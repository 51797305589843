exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "html,\nbody,\n.index_app_3Qs6X {\n    /* probably unecessary, transitional until layout is refactored */\n    width: 100%; \n    height: 100%;\n    margin: 0;\n\n    /* Setting min height/width makes the UI scroll below those sizes */\n    min-width: 1024px;\n    min-height: 640px; /* Min height to fit sprite/backdrop button */\n}\n\n/* @todo: move globally? Safe / side FX, for blocks particularly? */\n\n* { -webkit-box-sizing: border-box; box-sizing: border-box; }\n\n* Blocks工具栏宽度 */\n.scratchCategoryMenu {\n    width: 68px;\n}\n\n.blocklyFlyout, .blocklyScrollbarVertical.blocklyFlyoutScrollbar {\n    margin-left: 16px;\n}\n\n/* Hover显示完整语句块 */\n\n.blocklyFlyout:hover {\n    overflow: visible;\n}\n\n.injectionDiv {\n    overflow: hidden !important;\n}\n\n.blocklyFlyout:hover .blocklyWorkspace {\n    -webkit-clip-path: none;\n    clip-path: none;\n}", ""]);

// exports
exports.locals = {
	"app": "index_app_3Qs6X"
};